import * as styles from "./FooterNav.module.css";

import React, { useCallback, useRef, useState } from "react";

import { Link } from "gatsby";

const FooterNav = ({
  title,
  links,
}: {
  title: string;
  links: { to: string; title: string }[];
}) => {
  const navListRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const onClickHandler = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  return (
    <div className={`${styles.nav} ${isOpen ? styles.open : ""}`}>
      <button
        className={styles.navButton}
        type="button"
        onClick={onClickHandler}
        aria-label={title}
      >
        {title}
      </button>
      <div
        ref={navListRef}
        className={styles.navList}
        style={{
          maxHeight: `${
            isOpen
              ? `${(navListRef.current as HTMLDivElement).scrollHeight}px`
              : ""
          }`,
        }}
      >
        <ul>
          {links.map((link: { to: string; title: string }, i: number) => {
            return (
              <li key={`li_${i}`}>
                <Link key={`Link_${i}`} to={link.to} title={link.title}>
                  {link.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FooterNav;
