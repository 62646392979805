import * as styles from "./Footer.module.css";

import React, { lazy } from "react";

import FooterCTA from "./CTA/FooterCTA";
import FooterLinks from "./FooterLinks/FooterLinks";
import RenderOnViewportEntry from "../../../General/RenderOnViewportEntry";

// import EmergencyBar from "../EmergencyBar/EmergencyBar";
/**
 * A functional component representing the Footer section of the page.
 *
 * It conditionally renders the footer with an emergency bar and/or padding for the cookie bar,
 * based on the presence of  the showEmergencyBar and showCookieBar props.
 *
 * @param {boolean} showEmergencyBar - Whether to show the emergency bar in the footer.
 * @param {boolean} showCookieBar - Whether to show the cookie bar in the footer.
 * @return {JSX.Element} The Footer component.
 */
const Footer = ({
  showEmergencyBar = false,
  showCookieBar = true,
}: {
  showEmergencyBar?: boolean;
  showCookieBar?: boolean;
}) => {
  // Update the class name based on the showEmergencyBar and showCookieBar props
  const footerClassName = `${styles.footer} ${
    showEmergencyBar ? styles.footerWithEmergencyBar : ""
  } ${showCookieBar ? styles.footerWithCookieBar : ""}`;

  // Lazily import the Accreditations component as the slider script can cause delays on TBT
  const Accreditations = lazy(() => import("./Accreditations/Accreditations"));

  return (
    <footer className={footerClassName}>
      <RenderOnViewportEntry threshold={0.25} fallBackNode={<></>}>
        <Accreditations />
      </RenderOnViewportEntry>
      <FooterCTA />
      <FooterLinks />
    </footer>
  );
};

export default Footer;
