import { useEffect, useRef, useState } from "react";

const useFirstViewportEntry = (
  ref: React.RefObject<HTMLDivElement>,
  observerOptions?: IntersectionObserverInit
) => {
  const [entered, setEntered] = useState(false);
  if (typeof IntersectionObserver !== "undefined") {
    const observer = useRef(
      new IntersectionObserver(
        ([entry]) => setEntered(entry.isIntersecting),
        observerOptions
      )
    );
    useEffect(() => {
      const element = ref.current;
      const ob = observer.current;

      // stop observing once the element has entered the viewport for the first time.
      if (entered) {
        ob.disconnect();
        return;
      }

      if (element && !entered) ob.observe(element);

      return () => ob.disconnect();
    }, [entered, ref]);
  }
  return entered;
};
export default useFirstViewportEntry;
