import React, { Suspense, useRef } from "react";

import useFirstViewportEntry from "../../hooks/useFirstViewportEntry";

const RenderOnViewportEntry = ({
  children,
  threshold = 0,
  root = null,
  rootMargin = "0px 0px 0px 0px",
  fallBackNode = <p>Loading...</p>,
}: {
  children: React.ReactNode;
  threshold?: number;
  root?: Element | Document | null;
  rootMargin?: string;
  fallBackNode?: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const entered = useFirstViewportEntry(ref, { threshold, root, rootMargin });
  return (
    <div ref={ref}>
      {entered ? (
        <Suspense fallback={fallBackNode}>{children}</Suspense>
      ) : (
        fallBackNode
      )}
    </div>
  );
};

export default RenderOnViewportEntry;
